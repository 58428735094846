import React from 'react'
import './scss/Loading.scss';
export default () => {return (
                      <div className="Loading">
                        <div className="loading">
                          <div className="lds-ripple"><div></div><div></div>
                          </div>
                        </div>
                      </div>
  )}
