import React from 'react';
import './scss/SiteTitle.scss';
import logo from '../assets/images/logo.png';

export default () => {
  return (
    <div className="SiteTitle">
      <div className="logo-container">
        <img src={logo} alt="Tenetor logo"/>
      </div>
    </div>
  );
};
