import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import './scss/ContactCard.scss';

const ContactCard = (props) => {
  const { contactHeading } = props;
  const data = props.content[props.language];
  const contactData = getContact(data);
  const { fields } = data.items[0];
  const contactFields = contactData.fields;
  const contact = contactFields;

  if (typeof (contact) === 'undefined' || Object.entries(contact).length === 0) return (<Col></Col>);

  return (
    <div className="ContactCard">
      <div className="container contact-container">

        <div className="body-text-contact-heading">
          <p>
            {contactHeading}
          </p>
        </div>
        <Row>
          <Col sm={12}>
            <div className="body-text-contact">
              <p>{fields.siteName}</p>
              <p>{contact.mailingAddress}</p>
              <p>{contact.officeAddress}</p>
              <p>{contact.contactPerson}</p>
              <p>{contact.telephone}</p>
              <p>{contact.fax}</p>
              <p><a href={`mailto:${contact.email}`}>{contact.email}</a></p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    content: state.appState.contents,
    language: state.appState.currentLanguage
  }
}

const getContact = (data) => {
  let { fields } = data.items[0];
  let { includes } = data;

  if (typeof (includes) === 'undefined') return {};
  if (fields['contactDetails'] == null) return {};
  let { sys } = fields.contactDetails;
  let contact = includes.Entry.filter(x => x.sys.id === sys.id);

  return contact[0];
}

export default connect(mapStateToProps, {})(ContactCard);
