import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import Loading from './Loading';
import Desktop from './layouts/Desktop';
import Mobile from './layouts/Mobile';
import Language from './Language';
import Content from './Content';
import { updateContent, updateLanguage } from '../redux/actions/index'
import { CONTACT_EN, CONTACT_SV, CONTACT_FI, SITE_SLUG, LANG_EN, LANG_FI, LANG_SV } from '../const';

const HomePage = (props) => {

  let { lang } = props.match.params, contactHeading = '';
  let locale = '', contentType = '';

  switch (lang) {
    case LANG_FI:
      locale = 'fi-FI';
      contactHeading = CONTACT_FI;
      props.updateLanguage(LANG_FI);
      contentType = 'siteContentFi';
      break;
    case LANG_SV:
      lang = 'sv';
      locale = LANG_SV;
      contactHeading = CONTACT_SV;
      props.updateLanguage('sv');
      contentType = 'siteContentSv';
      break;
    default:
      contactHeading = CONTACT_EN;
      locale = 'en-US';
      props.updateLanguage(LANG_EN);
      contentType = 'content';
      lang = 'en';
      break;
  }

  return (
    <Content contentType={contentType} slug={`${SITE_SLUG}-${lang}`}>
      {() => {
        if (props.loading) {
          return <Loading />;
        }

        const data = props.content[props.language];

        if (typeof (data) === 'undefined' || data === null) return;

        const { sys } = data.items[0].fields.pageBackground;

        const image = data.includes.Asset.filter(x => {
          return x.sys.id === sys.id;
        });

        let homeBackground = {};
        let file = {};

        if (image.length > 0) {
          file = image[0].fields.file;
          let url = file.url.replace('downloads.ctfassets.net', 'images.ctfassets.net')
          homeBackground = {
            backgroundImage: `url(https:${url}?w=1920)`,
            backgroundSize: 'auto 125%',
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed'
          };

        }

        return (
          props.loading ?
            <Container  >
              <Row>
                <Col className="float-left">
                  <Language />
                </Col>
                <Loading />
              </Row>
            </Container>
            :
            <div>
              <Desktop lang={locale}
                data={data}
                contactHeading={contactHeading}
                homeBackground={homeBackground}
              />
              <Mobile lang={locale} data={data}
                contactHeading={contactHeading}
                homeBackground={homeBackground}
              />
            </div>
        );
      }}

    </Content>
  )
};

const mapStateToProps = (state) => {
  return {
    content: state.appState.contents,
    loading: state.appState.loading,
    language: state.appState.currentLanguage,
  }
}

export default connect(mapStateToProps, { updateContent, updateLanguage })(HomePage);
