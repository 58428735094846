import React from 'react';
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateLanguage } from '../redux/actions/index'
import { LANG_EN, LANG_FI, LANG_SV } from '../const';
import './scss/Language.scss'

const Language = (props) => {

  return (
    <Container className="Language">
      <div className=" language-container text-left mobile-col">
        <div id="en" className="btn-link">
          <Link to="/en" className={`${props.currentLanguage === LANG_EN ? 'no-border btn-maroon active' : 'no-border btn-maroon'}`} onClick={() => props.updateLanguage('en')} >EN</Link>
        </div>
        <div id="fi" className="btn-link">
          <Link to="/fi" className={`${props.currentLanguage === LANG_FI ? 'no-border btn-maroon active' : 'no-border btn-maroon'}`} onClick={() => props.updateLanguage('fi')} >FI</Link>
        </div>
        <div id="sv" className="btn-link">
          <Link to="/sv" className={`${props.currentLanguage === LANG_SV ? 'no-border btn-maroon active' : 'no-border btn-maroon'}`} onClick={() => props.updateLanguage('sv')} >SV</Link>
        </div>
      </div>
    </Container>

  );
};

const mapStateToProps = (state) => {
  return {
    currentLanguage: state.appState.currentLanguage
  }
}

export default connect(mapStateToProps, { updateLanguage })(Language);
