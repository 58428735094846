import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import Loading from './Loading';
import Desktop from './layouts/Desktop';
import Mobile from './layouts/Mobile';
import Content from './Content';
import { updateContent, updateLanguage } from '../redux/actions/index'
import { CONTACT_EN, LANG_EN } from '../const';

const LegalNoticePage = (props) => {
  let contactHeading = CONTACT_EN;
  let locale = 'en', contentType = 'legalNoticeContent', contentSlug = 'legal-notice-tenetor';
  props.updateLanguage(LANG_EN);

  return (
    <Content contentType={contentType} slug={contentSlug}>
      {() => {
        if (props.loading) {
          return <Loading />;
        }

        const data = props.content[props.language];

        if (typeof (data) === 'undefined' || data === null) return;

        const { sys } = data.items[0].fields.pageBackground;

        const image = data.includes.Asset.filter(x => {
          return x.sys.id === sys.id;
        });

        let homeBackground = {};

        let file = {};
        if (image.length > 0) {
          file = image[0].fields.file;
          let url = file.url.replace('downloads.ctfassets.net', 'images.ctfassets.net')
          homeBackground = {
            backgroundImage: `url(https:${url}?w=1920)`,
            backgroundSize: 'auto 125%',
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed'
          };

        }

        return (
          props.loading ?
            <Container  >
              <Row>
                <Col className="float-left">
                </Col>
                <Loading />
              </Row>
            </Container>
            :
            <div><Desktop lang={locale}
              data={data}
              contactHeading={contactHeading}
              homeBackground={homeBackground}
              page="legal-notice"
            />
              <Mobile lang={locale}
                data={data}
                contactHeading={contactHeading}
                homeBackground={homeBackground}
                page="legal-notice"
              />
            </div>
        );
      }}

    </Content>
  )
};

const mapStateToProps = (state) => {
  return {
    content: state.appState.contents,
    loading: state.appState.loading,
    language: state.appState.currentLanguage,
  }
}

export default connect(mapStateToProps, { updateContent, updateLanguage })(LegalNoticePage);
